<template lang="pug">
    .spreading
        ui-title(variant="subtitle-3", component="h3") Воздушно-капельный и&nbsp;воздушно-пылевой путь
        .item
            ui-paragraph.item-title(v-html="topItems.title")
            .icons
                .icon-wrap(
                    v-for="item in topItems.data",
                    :key="item.image"
                )
                    ui-title.title(variant="subtitle-3", component="p")
                        em(v-html="item.title")
                    img(:src="require(`../../../../../assets/images/articles/3/spreading/${item.image}.svg`)", :alt="item.title")
        .item.horizontal
            ui-paragraph.item-title(v-html="bottomItems.title")
            .icons
                .icon-wrap(
                    v-for="item in bottomItems.data",
                    :key="item.image"
                )
                    ui-title.title(variant="subtitle-3", component="p")
                        em(v-html="item.title")
                    img(:src="require(`../../../../../assets/images/articles/3/spreading/${item.image}.svg`)", :alt="item.title")
                    ui-paragraph(v-html="item.text")
</template>

<script>
import UiParagraph from '../../../../ui/Paragraph.vue';
import UiTitle from '../../../../ui/Title.vue';

export default {
    name: 'spreading',
    components: {
        UiParagraph,
        UiTitle,
    },
    computed: {
        topItems() {
            return {
                title: 'Распространение туберкулезных бактерий в&nbsp;воздухе',
                data: [
                    {
                        title: 'При кашле',
                        image: 'item-1',
                        text: null,
                    },
                    {
                        title: 'При чихании',
                        image: 'item-2',
                        text: null,
                    },
                ],
            };
        },
        bottomItems() {
            return {
                title: 'Дальнейшая судьба бактерий зависит от&nbsp;размера частиц мокроты и&nbsp;слизи',
                data: [
                    {
                        title: 'Частицы &lt; 140 мкм',
                        image: 'item-3',
                        // eslint-disable-next-line max-len
                        text: 'Испаряются, образуя капельные частицы размером около 5&nbsp;мкм, которые способны несколько дней оставаться в&nbsp;воздухе и&nbsp;легко переносятся его потоками.',
                    },
                    {
                        title: 'Частицы &gt; 140 мкм',
                        image: 'item-4',
                        // eslint-disable-next-line max-len
                        text: 'Оседают на&nbsp;поверхность и&nbsp;высыхают, превращаясь в&nbsp;пылинки с&nbsp;жизнеспособными бактериями внутри. При сильном движении воздуха пылинки вновь поднимаются.',
                    },
                ],
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.spreading {
    width: 100%;
    max-width: 750px;
    margin: rem(45) auto;

    .item {
        margin: rem(18) 0 rem(45) 0;

        .item-title {
            width: 100%;
            margin: rem(18) 0;
        }

        .icons {
            width: 100%;

            .icon-wrap {
                width: 100%;

                img {
                    max-width: 100%;
                    margin: rem(16) 0 rem(24);
                    display: block;
                    object-fit: contain;
                }

                .title {
                    padding-left: rem(30);
                }
            }
        }

        &.horizontal {
            .icons {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .icon-wrap {
                    width: 45%;

                    img {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(32) auto;
        padding: 0 var(--offset-x);

        .item {
            margin: rem-mobile(18) 0 rem-mobile(32) 0;

            .item-title {
                margin: rem-mobile(18) 0;
            }

            .icons {
                .icon-wrap {
                    margin: rem-mobile(18) 0;

                    img {
                        margin: rem-mobile(18) 0 rem-mobile(24);
                    }

                    .title {
                        padding-left: 0;
                    }
                }
            }

            &.horizontal {
                .icons {
                    display: block;

                    .icon-wrap {
                        width: 100%;
                    }
                }
            }

            &:not(.horizontal) {
                .icons {
                    .icon-wrap {
                        img {
                            max-height: rem-mobile(48);
                        }
                    }
                }
            }
        }
    }
}
</style>
